/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f4f4f9;
  color: #333;
  margin: 0;
  padding: 0;
}

#main {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

/* Container */
#container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 20px;
  margin: 20px;
  flex: 1;
}

/* Filters Section */
#filters {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 30%;
  margin-right: 10px;
}

#filters h2 {
  color: #003366;
  font-size: 16px;
  font-weight: bold; 
}

/* Output Section */
#output {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%; /* Make sure the container is wide enough */
  overflow-x: auto; /* Ensure the table fits within the card */
  margin-left: 10px;
}

#output h2 {
  color: #003366;
  font-size: 16px;
  font-weight: bold; 
}

/* Table Styles */

.table-container {
  max-height: 600px; /* Set this to whatever height you want */
  overflow-y: auto;
  position: relative;
}

#output table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875em; /* Reduced font size */
  table-layout: auto;
}

#output th, #output td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds ellipsis for overflowed text */
}

#output th {
  background-color: #003366; /* Professional dark color */
  color: white;
  font-weight: bold; /* Ensures header text stands out */
  cursor: pointer; /* Makes it clear the header is clickable */
  position: sticky; /* Makes the header sticky */
  top: 0; /* Sticks the header to the top */
  z-index: 2; /* Ensures the header is above the table body */
}

#output th:first-child {
  left: 0;
  z-index: 3; /* Ensure the first column header is above other headers */
}

#output td:first-child {
  position: sticky; /* Makes the first column sticky */
  left: 0;
  background-color: white; /* Matches the background color of the table */
  z-index: 1; /* Ensures the first column is above other cells */
}

#output th .sort-arrows {
  display: inline-block;
  margin-left: 10px;
}

#output th .sort-arrows button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 6px;
  padding: 0;
  display: flex;
  flex-direction: column;
}

#output th .sort-arrows button:hover {
  color: #ccc;
}

#output tr:nth-child(even) {
  background-color: #f2f2f2;
}

/* Adjusting padding and font size for better readability */
#output th, #output td {
  padding: 10px;
}

#output table tr:hover {
  background-color: #e7f4ff; /* Subtle hover effect */
}