/* General Form Styles */
form {
  display: flex;
  flex-direction: column;
}

form > div {
  margin-bottom: 15px;
}

/* Section Header */
#accordian-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f7f9;
  border: 0.7px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

form > div > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f7f9;
  padding: 10px;
  cursor: pointer;
}

form h4 {
  margin: 0;
  color: #003366;
  font-size: 14px;  /* Adjust the font size as needed */
  font-weight: 400;  /* Make the font bold */
}

form button {
  background: none;
  border: none;
  font-size: 14px;
  color: #003366;
  cursor: pointer;
}

form button:focus {
  outline: none;
}

/* Filter Inputs */
.input-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px 0;
}

form label {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #555;
  font-size: 14px;
}

form input[type="number"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  flex: 2;  /* Increased flex value to make the input boxes longer */
}

/* Submit Button */
form button[type="submit"] {
  background-color: #00509e;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

form button[type="submit"]:hover {
  background-color: #003366;
}
