/* Header Container */
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  box-sizing: border-box;
}

/* Navigation Bar */
.navbar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: #003366;
  padding: 12px 20px;
  position: relative;
  z-index: 1;
  border-bottom: 13px solid #648FB8; /* Light blue horizontal line under the navbar */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

/* Logo */
.logo {
  width: auto;
  height: 90px;
  position: absolute;
  top: 0px; /* Adjust this value as needed */
  left: 40px; /* Adjust this value as needed */
  z-index: 2;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Shadow effect */
}

/* Navigation Links */
.nav-links {
  display: flex;
  gap: 60px;
  margin-left: 320px; /* Adjust this value as needed to provide space for the logo */
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-family: 'Arial', sans-serif; /* Change this to your desired font family */
  font-size: 15px; /* Adjust font size as needed */
  padding: 10px; /* Add padding to create a box */
  border-radius: 5px; /* Optional: rounded corners for the box */
}

.nav-links a:hover {
  background-color: rgba(255, 255, 255, 0.2); /* Light background color on hover */
  text-decoration: none; /* Ensure no underline on hover */
}

/* Authentication Section */
.auth {
  color: white;
  margin-left: auto;
  font-family: 'Arial', sans-serif; /* Change this to your desired font family */
  font-size: 15px; /* Adjust font size as needed */
}