.upload-button-card {
    border: 1px solid #ddd;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f7f7f9;
  }
  
  .upload-button-card form {
    display: flex;
    flex-direction: column;
  }
  
  .upload-button-card button {
    margin-top: 10px;
    padding: 5px;
  }
  
  
.error-container {
  margin-top: 1rem;
  padding: 0.7rem;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
}

.success-container {
  margin-top: 1rem;
  padding: 0.7rem;
  background-color: #e5ffe5;
  border: 1px solid #9dd79d;
}

.error-message {
  margin: 0;
  font-size: 13px;
  color: #a52b37;
}

.success-message {
  margin: 0;
  font-size: 13px;
  color: rgb(50, 147, 50);
}
  