.input-table {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px; /* Adjust width as needed */
  margin: 0 auto;
  /* padding: 20px;
  background-color: #f7f7f9;
  border: 1px solid #ddd; */
}

.input-row {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.input-row label {
  flex: 1;
  margin-right: 1rem;
}

.input-row input {
  flex: 2;
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-width: 100px;
}

.input-row p#unit {
  margin: 0;
  font-size: 0.875rem;
  line-height: 2rem;
}

.input-row p#unit:first-of-type {
  margin-right: 5px; /* Space for units preceding the input field */
}

#input-button-dcf {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.error-messages {
  margin-top: 1rem;
  padding: 0.7rem;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;

}

.error {
  margin: 0;
  font-size: 13px;
  color: #a52b37;
}

/* Hide spin buttons for number inputs in Webkit browsers (Chrome, Safari) */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide spin buttons for number inputs in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
