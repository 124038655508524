.results-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.results-content {
  display: flex;
  flex-grow: 1;
  padding: 20px;
  justify-content: space-around;
  margin: 20px;
}

.left-panel, .right-panel {
  display: flex;
  flex-direction: column;
}

.left-panel {
  width: 30%;
  margin-right: 10px;
}

.right-panel {
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-height: 102vh; /* Set max height for the right panel */
  overflow: hidden; /* Prevents full page scroll */
}

.card {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card h3 {
  color: #003366;
  font-size: 16px;
  font-weight: bold; 
}

.card.upload-card {
  margin-bottom: 20px;
}

.results-tables {
  overflow-y: auto; /* Enable vertical scrolling */
}

.table-header {
  font-size: 15px;
  font-weight: bold;
  margin-top: 20px;
  color: #003366;
  border-bottom: 2px solid #003366;
  padding-bottom: 5px;
}

.result-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875em;
  table-layout: auto;
}

.result-table th,
.result-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.result-table th {
  background-color: #f2f2f2;
}

.result-table tr:hover {
  background-color: #ddd;
}

.result-table td:first-child {
  background-color: #f2f2f2;
  font-weight: bold;
}

.result-table th:first-child {
  font-weight: normal;
}